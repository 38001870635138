import { useState } from "react";
import { QuestionMark, Search } from "../shared/icons/icons";
import Markie from "./Markie";
import "./SearchBlock.css";

const SearchBlock = () => {
  const [showMarkie, setShowMarkie] = useState<boolean>(false);

  return (
    <div
      className="flex row-nowrap align-items-center justify-content-space-between block builder-quick-search"
      onMouseOver={() => setShowMarkie(true)}
      onMouseLeave={() => setShowMarkie(false)}
    >
      <div className="flex row-nowrap align-items-center builder-quick-search-group">
        <Search fill="#a1a1a1" />
        <p className="builder-quick-search-text">Quick search</p>
      </div>
      <div className="flex row-nowrap command-badges">
        <QuestionMark fill="#a1a1a1" />
        <div className="command-badges--pulse" />
      </div>
      {showMarkie && (
        <Markie
          posX={85}
          posY={280}
          subtitles="Cmd + k for quick search or try 'Openflow' as a term.."
        />
      )}
    </div>
  );
};

export default SearchBlock;
