import { useState, useEffect } from "react";
import useWindowSize from "../shared/hookes/useWindowSize";
import { CircledCrossmark } from "../shared/icons/icons";
import "./CardBlock.css";
import Markie from "./Markie";

export default function CardBlock({
  placement,
}: {
  placement: "home" | "header";
}) {
  const [showMarkie, setShowMarkie] = useState<boolean>(false);

  const size = useWindowSize();

  useEffect(() => {
    if (size && size.width !== undefined && size.width <= 575) {
      setShowMarkie(true);
    } else {
      setShowMarkie(false);
    }
  }, [size]);

  return (
    <div
      className={`flex row-nowrap align-items-center justify-content-space-between block card-block card-block-${placement}`}
      onMouseOver={() => setShowMarkie(true)}
      onMouseLeave={() => setShowMarkie(false)}
    >
      <div className="flex row-nowrap align-items-center justify-content-space-between icon-and-info">
        <div className="flex icon-wrapper">
          <img
            src={`/assets/flow/apps/slack.png`}
            alt={`icon`}
            className="icon card-icon"
          />
        </div>
        <div className="flex column-nowrap card-title-and-description">
          <div className="card-subheadline">Slack</div>
          <div className="card-headline">Send message</div>
        </div>
      </div>
      <div className="flex row-nowrap align-items-center card-end">
        <div className="flex card-error-badge">
          <CircledCrossmark />
          <div className="card-error-badge--pulse" />
        </div>
      </div>
      {showMarkie && (
        <Markie
          posX={
            size && size.width !== undefined && size.width <= 443 ? 95 : 105
          }
          posY={
            size && size.width !== undefined && size.width <= 443 ? 200 : 160
          }
          subtitles="After adding a card, configure &amp; deploy. Cont..."
          arrow="right"
        />
      )}
    </div>
  );
}
