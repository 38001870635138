import { NavLink } from "react-router-dom";
import Logo from "../../../logo/Logo";
import "./Navigation.css";

const Navigation = () => {
  return (
    <nav className="flex justify-content-space-between align-items-center navigation-wrapper">
      <Logo />
    </nav>
  );
};

export default Navigation;
