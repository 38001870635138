import { useState } from "react";
import { ChevronLeft, ChevronRight } from "../shared/icons/icons";
import "./CarouselBlock.css";
import Markie from "./Markie";

const CarouselBlock = () => {
  const [showMarkie, setShowMarkie] = useState<boolean>(false);

  return (
    <div
      className="flex row-nowrap carousel-group"
      onMouseOver={() => setShowMarkie(true)}
      onMouseLeave={() => setShowMarkie(false)}
    >
      <div className="flex align-items-center justify-content-center block carousel-block carousel-block-group">
        <ChevronLeft />
      </div>
      <div className="flex align-items-center justify-content-center block carousel-block carousel-block-group">
        <ChevronRight />
      </div>
      {showMarkie && <Markie />}
    </div>
  );
};

export default CarouselBlock;
