import { Fragment } from "react";
import Navigation from "../shared/components/navigation/Navigation";
import Header from "./Header";
import HelpBlock from "./HelpBlock";
import SearchBlock from "./SearchBlock";
import CardBlock from "./CardBlock";
import TrashBlock from "./TrashBlock";
import CarouselBlock from "./CarouselBlock";
import ShieldBlock from "./ShieldBlock";
import BuoyBlock from "./BuoyBlock";
import FinanceBlock from "./FinanceBlock";
import FormBlock from "./FormBlock";
import ButtonBlock from "./ButtonBlock";
import TodoBlock from "./TodoBlock";

import "./Homepage.css";

const Homepage = () => {
  return (
    <Fragment>
      <Navigation />
      <main className="main">
        <Header />
        <HelpBlock />
        <SearchBlock />
        <CardBlock placement={"home"} />
        <TrashBlock />
        <CarouselBlock />
        <ShieldBlock />
        <BuoyBlock />
        <FinanceBlock />
        <FormBlock />
        <ButtonBlock />
        <TodoBlock />
      </main>
    </Fragment>
  );
};

export default Homepage;
