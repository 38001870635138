import { useState } from "react";
import { Buoy } from "../shared/icons/icons";
import "./BuoyBlock.css";
import Markie from "./Markie";

const BuoyBlock = () => {
  const [showMarkie, setShowMarkie] = useState<boolean>(false);

  return (
    <div
      className="flex align-items-center justify-content-center block buoy-block"
      onMouseOver={() => setShowMarkie(true)}
      onMouseLeave={() => setShowMarkie(false)}
    >
      <Buoy />
      {showMarkie && <Markie />}
    </div>
  );
};

export default BuoyBlock;
