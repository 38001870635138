import { useState } from "react";
import { Shield } from "../shared/icons/icons";
import Markie from "./Markie";
import "./ShieldBlock.css";

const ShieldBlock = () => {
  const [showMarkie, setShowMarkie] = useState<boolean>(false);

  return (
    <div
      className="flex align-items-center justify-content-center block shield-block"
      onMouseOver={() => setShowMarkie(true)}
      onMouseLeave={() => setShowMarkie(false)}
    >
      <Shield />
      {showMarkie && <Markie />}
    </div>
  );
};

export default ShieldBlock;
