import { useEffect, useState } from "react";
import "./Markie.css";

const Markie = ({
  arrow = "left",
  posX = 100,
  posY = 50,
  subtitles,
}: {
  arrow?: "left" | "right";
  posX?: number;
  posY?: number;
  subtitles?: string;
}) => {
  const [number, setNumber] = useState<number>(0);

  const getRandomArbitrary = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  const generateRandomNumber = (min: number, max: number) => {
    const number = getRandomArbitrary(min, max);
    setNumber(number);
  };

  useEffect(() => {
    generateRandomNumber(0, 4);
  }, []);

  return (
    <div
      className={`flex align-items-center markie-dummy`}
      style={{
        top: posX,
        left: posY,
      }}
    >
      <div className={`flex markie-wrapper markie-arrow-${arrow}`}>
        <div className={`flex markie-video-wrapper`}>
          <video
            src={`/assets/markie/markie-explaining-${number}.mp4`}
            muted
            autoPlay
            playsInline
            loop
            className="markie-video"
            poster={`/assets/markie/markie-poster-${number}.png`}
          ></video>
        </div>
      </div>
      {subtitles && (
        <div className={`flex markie-subtitles markie-subtitles-${arrow}`}>
          {subtitles}
        </div>
      )}
    </div>
  );
};

export default Markie;
