import { useState } from "react";

import "./FormBlock.css";
import Markie from "./Markie";

const FormBlock = () => {
  const [showMarkie, setShowMarkie] = useState<boolean>(false);

  return (
    <div
      className="flex column-nowrap form-block"
      onMouseOver={() => setShowMarkie(true)}
      onMouseLeave={() => setShowMarkie(false)}
    >
      <label htmlFor={"email"} className="flex input-label">
        Access Token
      </label>
      <div className="input-wrapper">
        <div className="flex row-nowrap align-items-center input-text-form block">
          <p className="builder-quick-search-text">Your access token</p>
        </div>
        <div className="input-wrapper--pulse"></div>
      </div>
      <div className="flex align-self-flex-start input-wrapper-message input-wrapper-message--bottom input-wrapper-message--error">
        Access token is missing
      </div>
      {showMarkie && (
        <Markie
          posX={135}
          posY={90}
          subtitles="To avoid failing executions, add a valid access toke..."
        />
      )}
    </div>
  );
};

export default FormBlock;
