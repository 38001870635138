import { useState } from "react";
import { QuestionMark } from "../shared/icons/icons";
import "./HelpBlock.css";
import Markie from "./Markie";

const HelpBlock = () => {
  const [showMarkie, setShowMarkie] = useState<boolean>(false);

  return (
    <div
      className="flex align-items-center justify-content-center block help-block"
      onMouseOver={() => setShowMarkie(true)}
      onMouseLeave={() => setShowMarkie(false)}
    >
      <QuestionMark />
      <div className="help-block--pulse" />
      {showMarkie && <Markie />}
    </div>
  );
};

export default HelpBlock;
