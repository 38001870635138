import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Homepage from "./home/Homepage";
import Pricing from "./pages/Pricing";
import Demo from "./pages/Demo";
import Imprint from "./pages/Imprint";

import "./App.css";
import { useEffect } from "react";

const App = () => {
  useEffect(() => {
    /* @ts-ignore:disable-next-line */
    $crisp.push(["do", "chat:hide"]);

    /* @ts-ignore:disable-next-line */
    $crisp.push([
      "on",
      "chat:closed",
      () => {
        /* @ts-ignore:disable-next-line */
        $crisp.push(["do", "chat:hide"]);
      },
    ]);
  }, []);
  return (
    <BrowserRouter>
      <Toaster position="bottom-right" reverseOrder={false} />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/imprint" element={<Imprint />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
