import { NavLink } from "react-router-dom";
import "./Logo.css";

const Logo = () => {
  return (
    <NavLink to="/">
      <div className="flex row-nowrap align-items-center justify-content-center markie-logo">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width={32}
          height={32}
          viewBox="0 0 32 32"
        >
          <path
            id="markie-logomark"
            d="M28.9,0C30.6,0,32,1.4,32,3.2l0,25.7c0,1.8-1.4,3.2-3.1,3.2L3.1,32C1.4,32,0,30.6,0,28.8
	L0,3.2C0,1.4,1.4,0,3.1,0L28.9,0z M13.8,10.1c-0.7,0-1.3,0.2-1.8,0.5c-0.5,0.3-0.9,0.7-1.2,1.3l-0.1,0.2l-0.5-1.7H7.8l0,10.6l3.1,0
	l0-6c0-0.7,0.2-1.2,0.5-1.6c0.3-0.4,0.8-0.6,1.4-0.6c0.6,0,1,0.2,1.3,0.6c0.3,0.4,0.5,0.8,0.5,1.4l0,0.2l0,6l3,0l0-6
	c0-0.6,0.2-1.2,0.5-1.6c0.4-0.4,0.8-0.6,1.4-0.6c0.6,0,1,0.2,1.4,0.7c0.3,0.4,0.5,0.8,0.5,1.4l0,0.2l0,6l3,0l0-6.6
	c0-0.8-0.2-1.6-0.5-2.2c-0.3-0.6-0.8-1.1-1.4-1.5c-0.6-0.4-1.3-0.5-2-0.5c-0.8,0-1.5,0.2-2.1,0.6c-0.6,0.4-1,0.9-1.3,1.7
	c-0.3-0.7-0.7-1.3-1.3-1.7C15.4,10.3,14.6,10.1,13.8,10.1z"
          />
        </svg>
        <div id="markie-text" className="markie-logotext">
          Markie
        </div>
      </div>
    </NavLink>
  );
};

export default Logo;
