import { useState } from "react";
import "./ButtonBlock.css";
import Markie from "./Markie";

const ButtonBlock = () => {
  const [showMarkie, setShowMarkie] = useState<boolean>(false);

  return (
    <div
      className="flex align-items-center justify-content-center block button-block"
      onMouseOver={() => setShowMarkie(true)}
      onMouseLeave={() => setShowMarkie(false)}
    >
      Submit
      {showMarkie && <Markie posX={90} posY={110} />}
    </div>
  );
};

export default ButtonBlock;
