import { useState } from "react";
import { Plus } from "../shared/icons/icons";
import Markie from "./Markie";
import "./TodoBlock.css";

const TodoBlock = () => {
  const [showMarkie, setShowMarkie] = useState<boolean>(false);

  return (
    <div
      className="flex column-nowrap block todo-block"
      onMouseOver={() => setShowMarkie(true)}
      onMouseLeave={() => setShowMarkie(false)}
    >
      <div className="flex row-nowrap align-items-center justify-content-space-between todo-header">
        <div className="todo-headline">todo</div>
        <div className="flex todo-action">
          <Plus />
          <div className="todo-action--pulse"></div>
        </div>
      </div>
      <div className="flex column-nowrap todo-content">
        <div className="flex row-nowrap todo-top">
          <div className="flex align-items-center todo-check">
            <input
              type="checkbox"
              name="checkbox"
              id="checkbox"
              className="flex checkbox"
            />
            <div className="todo-check-text">Company page</div>
          </div>
        </div>
        <div className="flex todo-description">
          Add analytics plugin to our website and set up.
        </div>
      </div>
      {showMarkie && (
        <Markie
          posX={103}
          posY={130}
          arrow="right"
          subtitles="You can add your first todo by clicking the plus ..."
        />
      )}
    </div>
  );
};

export default TodoBlock;
