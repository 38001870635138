import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Navigation from "../shared/components/navigation/Navigation";
import PricingTier from "../shared/components/pricing/PricingTier";
import "./Pricing.css";

const Pricing = () => {
  return (
    <Fragment>
      <Navigation />
      <main className="flex column-nowrap main pricing">
        <header className="flex column-nowrap align-items-center justify-content-center pricing-header">
          <h1 className="pricing-headline">
            Easy pricing based on monthly users
          </h1>
          <p className="pricing-description">
            No hidden overcharges. Make your trials convert and let it pay for
            itself.
          </p>
        </header>
        <div className="flex row-wrap justify-content-space-between pricing-wrapper">
          <PricingTier
            title="Basic"
            description="For SaaS products"
            price="$99 / m"
            features={[
              "Up to 500 users",
              "10 markies",
              "Free video hosting",
              "Analytics",
            ]}
            buttonLabel="Start free trial"
            url="https://buy.stripe.com/3csdUEe0sbMV2I09AA"
          />
          <PricingTier
            title="Premium"
            description="For small businesses"
            price="$249 / m"
            features={[
              "Up to 5000 users",
              "50 markies",
              "Free video hosting",
              "Custom branding",
              "Analytics",
            ]}
            buttonLabel="Start free trial"
            highlight
            url="https://buy.stripe.com/7sI4k45tWbMVfuM5kl"
          />
          <PricingTier
            title="Enterprise"
            description="For established SaaS-businesses"
            price="$499 / m"
            features={[
              "Everything in Premium",
              "Unlimited users",
              "Unlimited markies",
            ]}
            buttonLabel="Contact us"
            url="mailto:nico@getmarkie.io"
          />
        </div>
        <p className="flex justify-content-center pricing-description pricing-description--small">
          7 day free trial on all tiers
        </p>

        <nav className="flex navigation center">
          <NavLink
            className={(data) =>
              data.isActive
                ? `flex nav-list-item nav-list-item--active`
                : `flex nav-list-item`
            }
            to="/demo"
          >
            Demo
          </NavLink>
          <NavLink
            className={(data) =>
              data.isActive
                ? `flex nav-list-item nav-list-item--active`
                : `flex nav-list-item`
            }
            to="/pricing"
          >
            Pricing
          </NavLink>
          <a
            href="https://app.getmarkie.io/login"
            target={"_blank"}
            className="flex nav-list-item"
            rel="noreferrer"
          >
            Login
          </a>
        </nav>
      </main>
    </Fragment>
  );
};

export default Pricing;
