import { Fragment } from "react";
import Navigation from "../shared/components/navigation/Navigation";
import "./Imprint.css";

const Pricing = () => {
  return (
    <Fragment>
      <Navigation />
      <main className="flex column-nowrap main imprint">
        <header className="flex column-nowrap align-items-center justify-content-center pricing-header">
          <h1 className="pricing-headline">Imprint</h1>
        </header>
        <div className="flex column-nowrap justify-content-space-between imprint-wrapper">
          <div className="flex column-nowrap imprint-block">
            <p className="imprint-text">Angaben gemäß § 5 TMG:</p>
            <p className="imprint-text imprint-text-bold">DNAconcepts GmbH</p>
            <p className="imprint-text">Europaring 10 D-49624 Löningen</p>
          </div>
          <div className="flex column-nowrap imprint-block">
            <p className="imprint-text">Geschäftsführer: Nico Lampe</p>
            <p className="imprint-text">
              Eingetragen beim Amtsgericht Oldenburg, HRB 216220
            </p>
            <p className="imprint-text imprint-text-bold">Kontakt:</p>
            <p className="imprint-text">Telefon: auf schriftliche Anfrage</p>
            <p className="imprint-text">E-Mail: hello@getmarkie.io</p>
          </div>
          <div className="flex column-nowrap imprint-block">
            <p className="imprint-text imprint-text-bold">Umsatzsteuer:</p>
            <p className="imprint-text">
              Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
            </p>
            <p className="imprint-text">DE338251352</p>
          </div>
          <div className="flex column-nowrap imprint-block">
            <p className="imprint-text imprint-text-bold">
              Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
            </p>
            <p className="imprint-text">Nico Lampe</p>
          </div>
          <div className="flex column-nowrap imprint-block">
            <p className="imprint-text imprint-text-bold">
              Haftungsausschluss (Disclaimer)
            </p>
            <p className="imprint-text">Haftung für Inhalte</p>
            <p className="imprint-text">
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
              Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
              verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
              jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die
              auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
              Entfernung oder Sperrung der Nutzung von Informationen nach den
              allgemeinen Gesetzen bleiben hiervon unberührt. Eine
              diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
              Kenntnis einer konkreten Rechtsverletzung möglich. Bei
              Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.
            </p>
          </div>
          <div className="flex column-nowrap imprint-block">
            <p className="imprint-text imprint-text-bold">
              Widerspruch Werbe-Mails
            </p>
            <p className="imprint-text">
              Der Nutzung von Kontaktdaten (die im Rahmen der Impressumspflicht
              veröffentlicht wurden) zur Übersendung von nicht ausdrücklich
              angeforderter Werbung und Informationen wird hiermit
              widersprochen. Der Seitenbetreiber behält sich rechtliche Schritte
              bei unverlangter Zusendung von Werbeinformationen, insbesondere
              bei Spam-E-Mails, vor.
            </p>
          </div>
          <div className="flex column-nowrap imprint-block">
            <p className="imprint-text imprint-text-bold">Urheberrecht</p>
            <p className="imprint-text">
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </p>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default Pricing;
