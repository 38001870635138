import "./Header.css";

import CardBlock from "./CardBlock";
import { NavLink } from "react-router-dom";
import { ArrowCircleLeft, ArrowCircleRight } from "../shared/icons/icons";
import Navigation from "../shared/components/navigation/Navigation";

const Header = () => {
  return (
    <header className="flex column-nowrap align-items-center justify-content-center homepage-header">
      <h1 className="flex justify-content-center homepage-headline">
        1.5x your Trial-Conversion now
      </h1>
      <p className="homepage-description">
        Every adoption needs a lightbulb-moment. <br className="break" />
        Convert more saas-trials and make users stick with self-service video
        onboarding.
      </p>
      <NavLink
        to="/demo"
        className={"flex align-items-center nav-list-item nav-list-item-bold"}
      >
        <ArrowCircleRight fill="#0b0b0b" />
        <span className="flex nav-list-item-text">See how it works</span>
        <ArrowCircleLeft fill="#0b0b0b" />
      </NavLink>

      <div className="flex column-nowrap align-items-center homepage-form">
        <div className="flex row-wrap input-group">
          <a
            target="_blank"
            href="https://getmarkie.io/pricing"
            className="input-submit"
            rel="noopener noreferrer"
          >
            Try Markie
          </a>
        </div>
        <div className="flex align-items-center trusted-by">
          7 day free trial
        </div>
        <nav className="flex navigation">
          <NavLink
            className={(data) =>
              data.isActive
                ? `flex nav-list-item nav-list-item--active`
                : `flex nav-list-item`
            }
            to="/demo"
          >
            Demo
          </NavLink>
          <NavLink
            className={(data) =>
              data.isActive
                ? `flex nav-list-item nav-list-item--active`
                : `flex nav-list-item`
            }
            to="/pricing"
          >
            Pricing
          </NavLink>
          <a
            href="https://app.getmarkie.io/login"
            target={"_blank"}
            className="flex nav-list-item"
            rel="noreferrer"
          >
            Login
          </a>
        </nav>
      </div>
      <CardBlock placement="header" />
    </header>
  );
};

export default Header;
