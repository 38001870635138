import { CheckmarkIcon } from "react-hot-toast";
import ActionButton from "../button/ActionButton";
import ExternalButton from "../button/ExternalButton";
import "./PricingTier.css";

const PricingTier = ({
  title,
  description,
  price,
  features,
  buttonLabel,
  highlight = false,
  url,
}: {
  title: string;
  description: string;
  price: string;
  features: string[];
  buttonLabel: string;
  highlight?: boolean;
  url?: string;
}) => {
  return (
    <div
      className={`flex column-nowrap pricing-tier pricing-tier-${
        highlight ? "highlight" : ""
      }`}
    >
      <div className="flex column-nowrap tier-header">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
      <div className="price">{price}</div>
      <ul className="flex column-nowrap features">
        <div className="description features-description">
          Features include:
        </div>
        {features &&
          features.length !== 0 &&
          features.map((feat) => {
            return (
              <li className="flex row-nowrap align-items-center feature">
                <CheckmarkIcon />
                <span className="text">{feat}</span>
              </li>
            );
          })}
      </ul>
      {title === "EnterpriseCrisp" ? (
        <ActionButton
          stretch
          onClick={() => {
            /* @ts-ignore:disable-next-line */
            $crisp.push(["do", "chat:show"]);
            /* @ts-ignore:disable-next-line */
            $crisp.push(["do", "chat:open"]);
          }}
          type="md"
          label={buttonLabel}
          version="primary"
          className={highlight ? "button-invert" : ""}
        />
      ) : (
        <ExternalButton
          stretch
          to={url ? url : "https://app.getmarkie.io/register"}
          type="md"
          label={buttonLabel}
          version="primary"
          className={highlight ? "button-invert" : ""}
          target="blank"
        />
      )}
    </div>
  );
};

export default PricingTier;
