import { Fragment, useEffect, useRef, useState } from "react";
import Navigation from "../shared/components/navigation/Navigation";
import {
  DemoChart,
  DemoFlowbuilder,
  DemoForm,
  DemoTasks,
} from "../shared/icons/icons";
import "./Demo.css";

const Demo = () => {
  let timerId: any = useRef(null);
  const [isTodoClicked, setTodoClicked] = useState<boolean>(false);

  const handleTodo = () => {
    setTodoClicked((val) => !val);
  };

  useEffect(() => {
    timerId.current = setTimeout(() => {
      setTodoClicked(false);
    }, 4000);

    return () => {
      clearTimeout(timerId.current);
    };
  }, [isTodoClicked]);

  return (
    <Fragment>
      <Navigation />
      <main className="flex column-nowrap main demo">
        <header className="flex column-nowrap align-items-center justify-content-center demo-header">
          <h1 className="demo-headline">Demo-time 🥳</h1>
          <p className="pricing-description">See how it works</p>
        </header>
        <div className="flex row-wrap justify-content-center demo-content">
          <div className="flex column-nowrap align-items-center demo-container">
            <div className="flex justify-content-center demo-box">
              <DemoForm />
            </div>
            <div className="flex align-items-center demo-info">
              <div className="pill">hover</div>
              <p className="text">Explain how the password should be set up</p>
            </div>
          </div>
          <div className="flex column-nowrap align-items-center demo-container">
            <div className="flex justify-content-center demo-box">
              <DemoTasks
                isTodoClicked={isTodoClicked}
                handleTodo={handleTodo}
              />
            </div>
            <div className="flex align-items-center demo-info">
              <div className="pill">click</div>
              <p className="text">Explain how the next steps based on action</p>
            </div>
          </div>
          <div className="flex column-nowrap align-items-center demo-container">
            <div className="flex justify-content-center demo-box">
              <DemoFlowbuilder />
            </div>
            <div className="flex align-items-center demo-info">
              <div className="pill">hover</div>
              <p className="text">Guide user in next steps</p>
            </div>
          </div>
          <div className="flex column-nowrap align-items-center demo-container">
            <div className="flex justify-content-center demo-box">
              <DemoChart />
            </div>
            <div className="flex align-items-center demo-info">
              <div className="pill">hover</div>
              <p className="text">Explain where extra info is needed</p>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default Demo;
