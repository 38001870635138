import { useState } from "react";
import "./FinanceBlock.css";
import Markie from "./Markie";

export default function FinanceBlock() {
  const [showMarkie, setShowMarkie] = useState<boolean>(false);
  return (
    <div
      className={`flex row-nowrap align-items-center justify-content-space-between block finance-block`}
      onMouseOver={() => setShowMarkie(true)}
      onMouseLeave={() => setShowMarkie(false)}
    >
      <div className="flex row-nowrap align-items-center justify-content-space-between icon-and-info">
        <div className="flex icon-wrapper">
          <input
            type="checkbox"
            name="checkbox"
            id="checkbox"
            className="checkbox"
          />
        </div>
        <div className="flex column-nowrap card-title-and-description">
          <div className="card-headline">Amazon</div>
          <div className="card-subheadline">Clement Noukir</div>
        </div>
      </div>
      <div className="flex row-nowrap align-items-center amount">
        €10,99{" "}
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 28 28"
          width="36"
          height="36"
          className="icon-default receipt"
        >
          <circle
            id="circ"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#D6FFD6"
            cx="14"
            cy="14"
            r="14"
          />
          <path
            id="receipt"
            fill="#0D850D"
            d="M9.8,7C8.9,7,8.1,7.7,8.1,8.7v8.7H7.6c-0.3,0-0.6,0.2-0.6,0.6v0.7C7,19.9,8.1,21,9.4,21h8.2
	c1.3,0,2.4-1.1,2.4-2.4V8.7C20,7.7,19.2,7,18.3,7H9.8z M9.8,8.1h8.5c0.3,0,0.6,0.2,0.6,0.6v9.9c0,0.7-0.6,1.3-1.3,1.3
	s-1.3-0.6-1.3-1.3v-0.7c0-0.3-0.2-0.6-0.6-0.6H9.2V8.7C9.2,8.3,9.5,8.1,9.8,8.1z M11.3,9.9c-0.3,0-0.6,0.2-0.6,0.6s0.2,0.6,0.6,0.6
	c0.3,0,0.6-0.2,0.6-0.6S11.6,9.9,11.3,9.9z M13.1,9.9c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.2,0.3,0.3,0.5,0.3h3.7
	c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.1-0.4,0-0.6C17.2,10,17,9.9,16.8,9.9H13.1z M11.3,12.2c-0.3,0-0.6,0.2-0.6,0.6s0.2,0.6,0.6,0.6
	c0.3,0,0.6-0.2,0.6-0.6S11.6,12.2,11.3,12.2z M13.1,12.2c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.2,0.3,0.3,0.5,0.3
	h3.7c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.2-0.3-0.3-0.5-0.3H13.1z M11.3,14.4c-0.3,0-0.6,0.2-0.6,0.6
	s0.2,0.6,0.6,0.6c0.3,0,0.6-0.2,0.6-0.6S11.6,14.4,11.3,14.4L11.3,14.4z M13.1,14.4c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.2-0.1,0.4,0,0.6
	c0.1,0.2,0.3,0.3,0.5,0.3h3.7c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.1-0.4,0-0.6s-0.3-0.3-0.5-0.3H13.1z M8.1,18.4h7.1v0.2
	c0,0.5,0.2,0.9,0.4,1.3H9.4c-0.7,0-1.3-0.6-1.3-1.3V18.4z"
          />
        </svg>
        <div className="receipt--pulse" />
      </div>
      <div className="floating-boarder"></div>
      {showMarkie && (
        <Markie
          posX={110}
          posY={320}
          subtitles="Invoices are sent every 1st of the month, click to do.."
        />
      )}
    </div>
  );
}
